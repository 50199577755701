/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../constants';
import handleUnauthorized from '../middleware/apiMiddleware';
import { serializeQueryParams } from '../../utils';

export const silverLiningClientApi = createApi({
  reducerPath: 'silverLiningClientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(handleUnauthorized),
  tagTypes: [],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: '/client/login',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .catch((err) => {
          });
      },
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: '/client/forgotPassword',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .catch((err) => {
          });
      },
    }),

    resendOtp: builder.mutation({
      query: (body) => ({
        url: '/client/resendTwoFactorCode',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {

          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    resendFirstOtp: builder.mutation({
      query: (body) => ({
        url: '/client/resend/otp',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {

          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    verify: builder.mutation({
      query: (body) => ({
        url: '/client/verify/twoFactorCode',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .catch((error) => {
          });
      },
    }),

    verifyOtp: builder.mutation({
      query: (body) => ({
        url: '/client/Verify/otp',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .catch((error) => {
          });
      },
    }),
    getAppointments: builder.query({
      // query: (appointmentFilter) => ({
      //   url: `/appointments?pastUpcomingFilter=${appointmentFilter}`,
      //   method: 'GET',
      // }),
      query: (Url) => ({
        url: Url,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getUpcomingAppointments: builder.query({
      query: (appointmentFilter) => ({
        url: `/appointments?pastUpcomingFilter=${appointmentFilter}`,
          method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getEvaluation: builder.query({
      query: () => ({
        url: '/evaluations',
          method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    evaluationSignatureByClient: builder.mutation({
      query: ({ body, Id }) => ({
        url: `/evaluation/${Id}/signature`,
        method: 'PUT',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    resetPassword: builder.mutation({
      query: (body) => ({
        url: '/client/resetPassword',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getClientCarePlanAttendance: builder.query({
      query: ({ ...queryParams }) => ({
        url: `carePlan/attendances?${serializeQueryParams({ ...queryParams })}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getClientCarePlan: builder.query({
      query: (clientId) => ({
        url: `/care-plan/${clientId}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getNotifications: builder.query({
      query: () => ({
        url: 'notifications',
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getNotificationsId: builder.query({
      query: (params) => ({
        url: `notifications/${params}`,
        method: 'GET',
      }),
    }),

    getZoomToken: builder.query({
      query: (params) => ({
        url: `appointment/${params}/video/token`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    appointmentReschedule: builder.mutation({
      query: ({ body, id }) => ({
        url: `/appointment/${id}/reschedule`,
        method: 'PUT',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    appointmentCancel: builder.mutation({
      query: ({ body, id }) => ({
        url: `/appointment/${id}/cancel`,
        method: 'PUT',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getAppointmentById: builder.query({
      query: (id) => ({
          url: `/appointment/${id}`,
          method: 'GET',
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useVerifyMutation,
  useVerifyOtpMutation,
  useGetZoomTokenQuery,
  useResendOtpMutation,
  useGetEvaluationQuery,
  useGetAppointmentsQuery,
  useGetUpcomingAppointmentsQuery,
  useGetNotificationsQuery,
  useResetPasswordMutation,
  useGetClientCarePlanQuery,
  useForgotPasswordMutation,
  useResendFirstOtpMutation,
  useGetNotificationsIdQuery,
  useEvaluationSignatureByClientMutation,
  useLazyGetClientCarePlanAttendanceQuery,
  useAppointmentRescheduleMutation,
  useAppointmentCancelMutation,
  useGetAppointmentByIdQuery,
} = silverLiningClientApi;
